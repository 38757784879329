.adm-center-popup-close {
  right: 10px ;
  top: -35px ;
  font-size: 28px;
}

.product-popup-page {
  width: 300px;
  height: 318px;
  background-image: url(./img/background-img.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 15px;
}


.product-header {
  margin-top: 13px;

  span {
    color: rgba(240, 207, 139, 1);
    font-size: 13px;
  }
}

.product-top-cont {
  text-align: center;

  .titles {
    color: rgba(204, 107, 51, 1);
    font-size: 12px;margin-top: 15px;
  }

  .amount {
    font-size: 31px;
    font-weight: bold;
    margin: 10px 0;

  }

  .time-limit {
    margin-bottom: 10px;
  }
}

.product-bottom-cont {
  .explain {
    font-size: 10px;
    width: 200px;
    color: rgba(146, 110, 61, 1);
    margin-bottom: 10px;
  }

  .agreement {
    .agreement-cont {
      display: flex;
      align-items: center;

      .check {
        img {
          width: 16px;
          height: 16px;
        }
      }

      .cont {
        width: 170px;
        font-size: 8px;
        color: rgba(146, 110, 61, 1);
        margin-left: 5px;

        span {
          color: rgba(30, 15, 11, 1);
        }
      }

    }
  }

  .apply-immediately {
    height: 40px;
    background-image: url(./img/batn.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
    text-align: center;
    line-height: 36px;
    font-weight: bold;
    color: rgba(29, 18, 13, 1);
    margin-top: 10px;
    position: relative;
    font-size: 17px;
  }

  .apply-immediately-no {
    width: 198px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-weight: bold;
    background-color: #999;
    margin-top: 4px;
    border-radius: 20px;
  }
}
.agreemen{
  padding: 10px 15px 30px 15px;
  color: #4B4B4B;
  font-size: 14px;
  .tits{
    font-size: 18px;
    font-weight: 500;
    color: #241B1B;
    text-align: center;
    padding: 12px 0 25px 0;
  }
  .cont{
    padding-bottom: 20px;
  }
  .MustKnow{
border-radius: 4px;
line-height: 32px;
background: rgba(22, 119, 255, 0.1);color: #1677FF;
margin-bottom: 20px;
  }
}

@primary-color: #1890ff;