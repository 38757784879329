.boxModal{
  width: 280px;
  height: 280px;
  font-weight: bold;
}
.child{
  font-size: 12px;
}
.adm-modal-footer .adm-modal-button{
  font-size: 14px;
  height: 30px;
  border: 2px solid #1677ff;
}
.adm-modal-footer.adm-space{

}

@primary-color: #1890ff;