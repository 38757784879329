
  .title{
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 14px;
  }
  .btn{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    span{
      height: 23px;
      line-height: 23px;
      font-size: 11px;
      text-indent: 10px;
      background: linear-gradient(135deg,#2bb0ff,#1378fe);
      color: #fff !important;
    }
    button{
      border: none;
      height: 44px;
      line-height: 48px;
      text-align: center;
      color: #1378fe;
      font-weight: 700;
      font-size: 15px;
      background: #FFFFFF;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

@primary-color: #1890ff;